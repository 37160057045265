import React from "react";
import { SearchIcon, LabeledIconInput, colors } from "@commonsku/styles";
import { FilterMainComponentProps } from "../Filter";

const QueryInput = ({
  values,
  onChange,
  placeholder = "Search",
  onKeyPress,
}: FilterMainComponentProps) => (
  <LabeledIconInput
    Icon={<SearchIcon />}
    iconColor={colors.neutrals["60"]}
    iconLabelStyles={{ backgroundColor: "transparent" }}
    value={values as string}
    placeholder={placeholder}
    onChange={(e) => {
      onChange(e.target.value);
    }}
    onKeyDown={onKeyPress}
  />
);

export default QueryInput;
